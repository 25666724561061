import React from 'react';

import RichOrText from './RichOrText';

const Stripe = ({ className = '' }) => (
  <div className={`${className} stripes3 lg:top-6 md:top-5 top-4 flex-none xl:w-[10vw]`} />
);

const StripeSection = ({
  children,
  className,
  description,
  descriptionProps = {},
  title,
  ...rest
}) => {
  const descriptionContent = description?.richText || description;
  const renderContent = (className) => (
    <div className={`${className} mx-auto w-full`}>
      {children}
    </div>
  );
  return (
    <div className={[className, 'mx-auto max-w-7xl w-full'].filter(Boolean).join(' ')} {...rest}>
      <div className="text-blue text-left z-10 pt-12 relative w-full">
        <div className="flex gap-4 items-start mb-4 relative w-full lg:max-w-[90vw] mx-auto">
          {title && <Stripe className="relative xl:absolute xl:left-0 xl:-translate-x-full xl:rotate-180" />}
          <div className="w-full">
            <h2 className="lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-medium tracking-tighter title1 pl-2 pr-4">
              {title}
            </h2>
            {descriptionContent && (
              <div className="w-full text-blue sm:text-xl text-lg mt-6 xl:mt-8 pl-2 pr-4">
                <RichOrText
                  {...descriptionProps}
                  content={descriptionContent}
                  components={{
                    ...descriptionProps.components,
                    paragraph: ({ children }) => (
                      <p className="xl:text-xl lg:text-lg text-md text-blue !max-w-none m-0">
                        {children}
                      </p>
                    ),
                  }}
                />
              </div>
            )}
            {renderContent('hidden xl:block')}
          </div>
        </div>
        {renderContent('xl:hidden px-4')}
      </div>
    </div>
  );
};

export default StripeSection;
