import * as React from "react";

const CustomLeftArrow = ({ onClick, ...rest }) => (
  <div onClick={() => onClick()} className="left-arrow" {...rest} />
);
const CustomRightArrow = ({ onClick, ...rest }) => {
  return <div className="right-arrow" onClick={() => onClick()} {...rest} />;
};

export {
  CustomLeftArrow,
  CustomRightArrow,
};
