import * as React from "react"
import { graphql, navigate } from "gatsby"

import CustomerExperience from '../Elements/CustomerExperience';
import CaseStudies from "../Elements/CaseStudies";

const CaseStudySection = ({ slice }) => {
  const { primary, items } = slice
  const { heading } = primary
  return (
    <>
      <div className="w-full lg:mt-24 mt-12 mb-12">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-2xl tracking-tighter font-medium my-4">{heading && heading.text}</h2>
          <CaseStudies
            studies={(items || []).map(({
              casestudy_item: {
                document: {
                  data: {
                    title,
                    description,
                    image,
                    mobile_image,
                    client_logo,
                    casestudy_link: href,
                  } = {},
                } = {},
              } = {},
            }) => ({
              client_logo,
              description,
              href,
              image,
              mobile_image,
              title,
            }))}
          />
        </div>
      </div>
      <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
        <CustomerExperience items="clients" />
      </div>
    </>
  );
};

export default CaseStudySection;

export const query = graphql`
  fragment casestudiesESlice on PrismicSolutionsEnterpriseDataBodyCasestudies {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
    }
    items {
      casestudy_item {
        document {
          ... on PrismicCasestudies {
            id
            data {
              casestudy_link
              title {
                text
                html
              }
              description {
                text
                html
              }
              image {
                dimensions {
                  width
                  height
                }
                fluid(maxHeight: 376, maxWidth: 530) {
                  srcWebp
                  srcSetWebp
                }
              }
              mobile_image {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 230, maxHeight: 260) {
                  srcWebp
                  srcSetWebp
                }
              }
              client_logo {
                dimensions {
                  width
                  height
                }
                fluid(maxWidth: 212, maxHeight: 90) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
