import React from 'react';

import { PrismicRichText } from '@prismicio/react';

const RichOrText = ({ content, ...props }) => (
  typeof content === 'string' ? (
    <p style={{ maxWidth: 'none' }}>{content}</p>
  ) : Array.isArray(content) ? (
    <PrismicRichText {...props} field={content} />
  ) : null
);

export default RichOrText;
