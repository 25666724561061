import * as React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {CustomLeftArrow, CustomRightArrow} from "./CustomArrows";

const Testimonial1 = ({ data, theme = "dark" }) => {
  const hfont = theme === 'dark' ? 'text-white' : 'text-blue';
  const pfont = theme === 'dark' ? 'text-gray-300' : 'text-blue';

  // const settings = {
  //     slidesToShow: 2,
  //     slidesToScroll: 2,
  //     adaptiveHeight: true,
  //     infinite: true,
  //     rtl:false,
  //     arrows: true,
  //     dots: false,
  //     centerMode: true,
  //     centerPadding: '8%',     
  //     // dots: false,
  //     // arrow: true,
  //     // speed: 500,
  //     // slidesToShow: 2,
  //     // slidesToScroll: 1,
  //     // centerMode: true,
  //     // centerPadding: '7%',        
  //     // swipeToSlide: true,
  //     // infinite: true,
  //     // cssEase: 'linear',
  //     // autoplay: true,
  //     // autoplaySpeed: 2000,
  //     responsive: [
  //         {
  //             breakpoint: 1024,
  //             settings: {
  //                 slidesToShow: 2,
  //             },

  //             breakpoint: 767,
  //             settings: {
  //                 slidesToShow: 1,
  //                 slidesToScroll: 1,
  //                 centerMode: false,
  //             },
  //         },
  //     ],
  // };


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      centerMode: true,
    },
    desktop: {
      breakpoint: { max: 3000, min: 991 },
      items: 2,
      centerMode: true,
    },
    tablet: {
      breakpoint: { max: 990, min: 464 },
      items: 1,
      centerMode: false,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: false,
    },
  };

  const renderTestimonial = (mobile) => ({
    testimonial: {
      document: {
        data: {
          author,
          designation,
          content,
          image,
        },
      },
    },
  }) => {
    const characterLimit = 400;
    const sanitizedContent = `${content.slice(0, characterLimit)}${content.length > characterLimit ? '...' : ''}`;
    return (
      <div key={author} title={content}>
        <div className={`rounded-2xl tm-box bg-white/[.15] xl:p-10 p-6 my-6 ml-6 border-b-8 border-solid border-green-400 tbox-shadow ${mobile ? 'mx-4' : 'ml-6'}`}>
          <div className="flex items-center mb-6 author-img">
            <img loading="lazy" src={image.fluid.srcWebp} srcSet={image.fluid.srcSetWebp} alt={image} width={88} height={88} className="mb-0" />
            <div className="pl-5">
              <div className={`font-inter text-lg font-bold ${hfont}`}>{author}</div>
              <p className={`md:text-base text-sm mb-0 ${pfont}`}>{designation}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className={`sm:text-lg tm-text italic testimonial-content mb-0 ${pfont} ${mobile ? 'text-base' : 'md:text-base text-sm'}`}>
              {`"${sanitizedContent}"`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full overflow-hidden testimonial">
      <div className="carousel-outer mob-hidden" >
        <Carousel
          responsive={responsive}
          infinite={true}
          centerMode={true}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          arrow={false}
        >
          {data?.map(renderTestimonial('desktop'))}
        </Carousel>
      </div>
      <div className="carousel-outer desktop-hidden" >
        <Carousel
          responsive={responsive}
          infinite={true}
          centerMode={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          arrow={false}
        >
          {data?.map(renderTestimonial('mobile'))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial1
