import * as React from "react"
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Button1 } from "../Elements/Buttons"
const CalculateSection = ({ slice }) => {
    const { primary } = slice    
    const { heading, rioContent, image, data_integration_image } = primary
    return (
        <div className="max-w-7xl mx-auto px-4">
            <div className="bg-white md:pt-16 pt-8 pb-6 lg:px-14 md:px-8 px-4 -mt-32 relative z-30 shadow-2xl rounded-3xl">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-6 relative">
                    <div className="xl:pr-40 relative">
                        <div className="absolute -top-16 right-0 md:block hidden"><StaticImage src="../../assets/images/Solutions/roi-bg.png" alt="" width="200" height="50" /></div>
                        <div className="absolute -top-8 -right-4 md:hidden block"><StaticImage src="../../assets/images/Solutions/roi-bg1.png" alt="" width="100" height="70" className="rounded-tr-3xl" /></div>
                        <h2 className="lg:text-5xl text-2xl font-medium sm:pr-0 pr-16 text-blue lg:mb-10 mb-8">{heading && heading.text}</h2>
                        <p className="text-blue text-xl">{rioContent && rioContent.text}</p>
                        <Button1>Contact Us</Button1>
                    </div>
                    <div className="relative">
                        <GatsbyImage
                          image={getImage(image)} 
                          alt={heading && heading.text} 
                          className="shadow-xl rounded-2xl z-10"
                        />
                        <div className="absolute -bottom-12 -left-28 md:block hidden z-0">
                            <img
                                loading="lazy"
                                src={data_integration_image.fluid.srcWebp}
                                srcSet={data_integration_image.fluid.srcSetWebp}
                                width="200" height="160"
                                alt={heading && heading.text}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalculateSection

export const query = graphql`
  fragment solutionsECalculateYourRoiSlice on PrismicSolutionsEnterpriseDataBodyCalculateYourRoi {
    id
    slice_type
    primary {
        heading {
            text
            html
        }
        rioContent : content {
                  text
                  html
                }
        image {
            gatsbyImageData(layout: FULL_WIDTH)
        }
        data_integration_image {
            dimensions {
            width
            height
            }
            fluid(maxWidth: 480, maxHeight: 372) {
            srcSetWebp
            srcWebp
            }
        }
    }
  }
`
