import React from "react"
import { graphql } from "gatsby";
import Testimonial1 from "../Elements/Testimonials1"

const Testimonials =  ({ slice }) => {
    const { primary, items } = slice
    const { heading, content } = primary
    return (
        <div className="bg-gradient xl:pt-32 md:pt-24 pt-16 pb-56 overflow-hidden">
            <div className="ml-auto flex lg:flex-row flex-col relative">
                <div className="w-full px-4">                    
                <h2 className="xl:w-96 lg:pl-4 lg:ml-auto xl:text-7xl xs:text-5xl text-4xl text-white font-medium mb-4">
                   {heading?.text}
                </h2>
                <p className="xl:w-96 lg:pl-4 lg:ml-auto text-white sm:text-xl text-lg mb-0">{content && content}</p>
                </div>
                <div className="lg:w-2/3 w-full relative">
                    <Testimonial1 data={items}/>
                </div>
            </div>
        </div>
    )
}
export default Testimonials

export const query = graphql`
  fragment solutionsETestimonialsSlice on PrismicSolutionsEnterpriseDataBodyTestimonials {
    id
    slice_type
    primary {
    heading {
        text
        richText
    }
    content
    }
    items {
    testimonial {
        document {
        ... on PrismicTestimonials {
            id
            data {
            author
            content
            designation
            image {
                fluid {
                srcWebp
                srcSetWebp
                }
            }
            }
        }
        }
    }
    }
  }
`
