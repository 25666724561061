import React from "react"
import { 
  graphql,
  navigate,
  useStaticQuery,
} from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import { Button1, Button3 } from "../Elements/Buttons"

const HeroSection = ({ slice }) => {
    const { primary, items } = slice
    const { heading, sub_heading } = primary
    const {
      prismicSitesettings: {
        data: heroLinks = {},
      } = {},
    } = useStaticQuery(graphql`
      query enterpriseHeroLinks {
        prismicSitesettings {
          data {
            get_started_button_text
            get_started_button_link
            watch_demo_button_text
            watch_demo_button_link
          }
        }
      }
    `)
    return (
        <div className="bg-gradient lg:pt-52 lg:pb-40 md:pt-40 md:pb-32 pt-32 pb-20">
            <div className="max-w-6xl mx-auto px-4">
                <div className="relative text-center Herotitle">
                    <span className="stripes1 absolute"></span>
                    <h1 className="xl:text-8xl lg:text-7xl sm:text-6xl text-5xl text-white font-medium tracking-tighter mb-8">{heading && heading.text}</h1>
                    <span className="stripes2 absolute"></span>
                </div>
                <p className="text-white text-xl mx-auto text-center">{sub_heading && sub_heading}</p>
                <div className="flex justify-center items-center text-center md:flex-row flex-col md:gap-4">
                    <Button1 button_link={heroLinks?.get_started_button_link}>
                      {heroLinks?.get_started_button_text}
                    </Button1>
                    <Button3 button_link={heroLinks?.watch_demo_button_link}>
                      {heroLinks?.watch_demo_button_text}
                    </Button3>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4">
                <div className="lg:gap-16 md:gap-10 gap-14 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-24">
                    {items && items.map((item, index)=>{
                        return(
                            <div className="text-center" key={index}>
                                 <img
                                    loading="lazy"
                                    src={item.usecase_image.fluid.srcWebp}
                                    srcSet={item.usecase_image.fluid.srcSetWebp}
                                    width="170" height="170" 
                                    alt={item.usecase_content && item.usecase_content}
                                className="mx-auto"/>
                                <p className="text-white sm:text-xl text-lg mt-8 mb-4 mx-auto">{item.usecase_content && item.usecase_content}</p>
                                <Button3 button_link={item.button_link}>
                                  {item.button_text}
                                </Button3>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default HeroSection
export const query = graphql`
  fragment solutionsEHeroSlice on PrismicSolutionsEnterpriseDataBodyHero {
    id
    slice_type
    primary {
    heading {
        text
        html
    }
    sub_heading
    }
    items {
    usecase_content
    button_text
    button_link
    usecase_image {
        fluid(maxWidth: 390, maxHeight: 390) {
        srcWebp
        srcSetWebp
        }
        dimensions {
        width
        height
        }
    }
    }
  }
`
