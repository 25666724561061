import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ButtonGetStarted from '../Buttons/ButtonGetStarted'
import { FormButton } from '../../frontend-shared/components';
import Slider from "react-slick";

var settings = {
    dots: false,
    arrows:false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                autoplay:true,
                autoplaySpeed:0,
                speed: 1000,
                cssEase:"linear",
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                autoplay:true,
                autoplaySpeed:0,
                speed: 1000,
                cssEase:"linear",
            },
        },
    ],
};

const CustomerExperience = ({
  heading,
  trustedby_text,
  items,
  theme = 'dark',
  button_text,
  button_link,
}) => {
    const { siteSettings: { data: site }, clients } = useStaticQuery(
        graphql`
          query {
            siteSettings : prismicSitesettings {
                data {
                  automateshipping_operation_banner_text {
                    text
                    html
                  }
                  automate_operation_banner_subtext
                }
            }
            clients : allPrismicBrand(
              filter: { data: { rank: { ne: null }, relation: { eq: "client" } } }
              sort: { fields: data___rank, order: DESC }
              limit: 5
            ) {
              edges {
                node {
                  uid
                  data {
                    name
                    logo_light {
                      dimensions {
                        height
                        width
                      }
                      fluid {
                        srcSetWebp
                        srcSet
                      }
                    }
                    logo_dark {
                      dimensions {
                        height
                        width
                      }
                      fluid {
                        srcSetWebp
                        srcSet
                      }
                    }
                  }
                }
              }
            }
          }
        `
    )
    const title = heading || site.automateshipping_operation_banner_text
    const subtext = trustedby_text || site.automate_operation_banner_subtext
    const entries = items === 'clients' ? clients.edges.map(
      ({ node: { uid, data } }) => ({ uid, ...data, clientlogo: theme === 'dark' ? data.logo_light : data.logo_dark }),
    ) : items;
    const textColor = theme === 'dark' ? 'text-white' : 'text-blue';
    return (
      <>
        <div className="max-w-5xl mx-auto md:text-center text-left px-4">
          <div className="relative title z-10">
            <span className="stripes1 absolute"></span>
            <h2 className={`${textColor} lg:text-7xl md:text-6xl sm:text-5xl text-3xl font-medium tracking-tighter mb-8`}>{title?.text}</h2>
            <span className="stripes2 absolute"></span>
          </div>
          <div className="flex flex-col items-center w-full">
            {button_text ? (
              <a className="no-underline py-3" href={button_link}>
                <FormButton color="primary" size="lg">
                  {button_text}
                </FormButton>
              </a>
            ) : (
              <ButtonGetStarted />
            )}
          </div>
        </div>
        {subtext && entries?.length && (
          <div className="max-w-5xl mx-auto md:text-center text-left px-4 md:pt-24 pt-12">
            <p className={`${textColor} text-lg font-normal text-white mx-auto mb-6`}>{subtext}</p>
            <div className="flex sm:justify-between lg:justify-center justify-center items-center  lg:gap-8 gap-4 md:flex-nowrap flex-wrap ">
            <Slider {...settings} className="w-full mb-5 flex flex-row justify-between customer-exp-slider items-center">
              {entries?.map((item, index) => (
                <img
                  alt={trustedby_text}
                  height={Math.round(item.clientlogo.dimensions.height / 2)}
                  loading="lazy"
                  src={item.clientlogo.fluid.srcWebp}
                  srcSet={item.clientlogo.fluid.srcSetWebp}
                  width={Math.round(item.clientlogo.dimensions.width / 2)}
                />
              ))}
            </Slider>
            </div>
          </div>
        )}
      </>
    );
}
export default CustomerExperience
