import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/SolutionsEnterprise"
import Layout from "../components/layout"
import SEO from "../components/seo"



const SolutionsEnterprise = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicSolutionsEnterprise
  const page = pageData.data || {}
  return(
    <Layout
      className="background1"
      theme={props.pageContext.header_theme && props.pageContext.header_theme}
    >
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />
    </Layout>
  );
};

export default SolutionsEnterprise

export const query = graphql`
  query solutionsEnterpriseQuery($uid: String!){
    prismicSolutionsEnterprise(uid: {eq: $uid}) {
        uid
        id
        type
        data{
          body{
                ... on PrismicSliceType {
                  slice_type
                }
                ...solutionsEHeroSlice
                ...solutionsEContentSlice
                ...solutionsETestimonialsSlice
                ...solutionsECalculateYourRoiSlice
                ...casestudiesESlice
            }
        }
    }
  }
`
