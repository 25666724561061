import * as React from "react"
import Link from "./Link"

export const Button1 = ({ button_link, ...rest }) => (
  <Link
    className="bg-red font-medium sm:text-xl text-base py-3 px-7 text-white rounded-full no-underline mt-4 tracking-wide uppercase inline-block"
    href={button_link}
    {...rest}
  />
);

export const Button2 = ({ button_link, ...rest }) => (
  <Link
    className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block"
    href={button_link}
    {...rest}
  />
);

export const Button3 = ({ button_link, ...rest }) => (
  <Link
    className="bg-light shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-white rounded-full no-underline mt-4 tracking-wide uppercase inline-block"
    href={button_link}
    {...rest}
  />
);
