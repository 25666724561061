import HeroSection from "./HeroSection"
import ContentBlks from "./ContentBlks"
import Testimonials from "./Testimonials"
import CalculateSection from "./CalculateSection"
import CaseStudySection from "./CaseStudy"

export const components = {
  hero: HeroSection,
  solutions_content: ContentBlks,
  testimonials: Testimonials,
  calculate_your_roi: CalculateSection,
  casestudies: CaseStudySection,
}
