import React from "react"
import { graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import StripeSection from '../Elements/StripeSection';
import LinearContentBlock from '../Elements/LinearContentBlock';

const ContentBlks =  ({
  slice: {
    primary: {
      title,
      description,
    },
    items,
  },
}) => (
  <StripeSection title={title} description={description}>
    <LinearContentBlock
      entries={items.map(({ heading, content, ...rest }) => ({
        title: heading?.text,
        content: content?.richText,
        ...rest,
      }))}
      shadow
    />
  </StripeSection>
);

export default ContentBlks

export const query = graphql`
  fragment solutionsEContentSlice on PrismicSolutionsEnterpriseDataBodySolutionsContent {
    id
    slice_type
    primary {
      title
      description { richText }
    }
    items {
      heading {
        text
        html
      }
      content {
        text
        html
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
      button_text
      button_link
    }
  }
`
