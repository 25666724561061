import React from 'react';

import { FormButton } from '../../frontend-shared/components';

import Link from './Link';

const CaseStudies = ({ cols = 2, studies, title }) => !studies?.length ? null : (
  <div className="max-w-7xl mx-auto px-4 flex flex-col gap-8">
    {title && (
      <h2 className="text-blue lg:text-6xl md:text-5xl text-4xl tracking-tighter font-medium">
        {title}
      </h2>
    )}
    <div className={`grid md:grid-cols-${cols} grid-flex-col gap-5 items-stretch`}>
      {studies.map(({
        description,
        href,
        image,
        mobile_image,
        title,
      }) => (
        <div className="bg-gradient rounded-3xl lg:px-10 px-6 pt-8 flex flex-col justify-between overflow-hidden">
          <Link className="no-underline flex flex-col h-full" href={href}>
            <h3 className="text-white lg:text-lg text-base font-medium tracking-wider mb-8">
              {title?.text}
            </h3>
            <h4 className="text-white lg:text-4xl text-2xl font-medium">{description?.text}</h4>
            <div className="mt-8 grow h-full" />
            <div className="flex items-end justify-between gap-3 md:flex-row flex-row-reverse">
              <div className="lg:-ml-10 md:-ml-6 -mr-6">
                <div className="md:block hidden">
                  <img
                    loading="lazy"
                    src={image?.fluid.srcWebp}
                    srcSet={image?.fluid.srcSetWebp}
                    width={Math.round(image?.dimensions.width/2)}
                    height={Math.round(image?.dimensions.height/2)}
                    alt={title?.text}
                    className="mb-0"
                  />
                  </div>
                  <div className="md:hidden block">
                  <img
                    loading="lazy"
                    src={mobile_image?.fluid.srcWebp}
                    srcSet={mobile_image?.fluid.srcSetWebp}
                    width={Math.round(mobile_image?.dimensions.width/2)}
                    height={Math.round(mobile_image?.dimensions.height/2)}
                    alt={title?.text}
                    className="mb-0"
                  />
                </div>
              </div>
              <div className="hidden md:flex p-4">
                <FormButton color="tertiary" size="lg">
                  LEARN MORE
                </FormButton>
              </div>
              <div className="flex md:hidden py-4">
                <FormButton color="tertiary" size="md">
                  LEARN MORE
                </FormButton>
              </div>
            </div>
         </Link>
       </div>
      ))}
    </div>
  </div>
);

export default CaseStudies;
